.login__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .login__control label {
    margin-bottom: 0.5rem;
    display: block;
    color: #ffffff
  }
  
  .login__control input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ffffff;
    width: 20rem;
    max-width: 100%;
  }
  
  .login__actions {
    text-align: right;
  }