.region-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.drop {
  display: flex;
  width:100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "cuprum";
  justify-content: space-between;
  background-color: rgb(140, 139, 193);
  color:rgb(255, 255, 255);
  font-family: "cuprum";
  font-weight: 400;
  border-radius: 10px;
  font-size: 24px;
  margin-left: .5rem;
  margin-right: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.style-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "cuprum";
  justify-content: space-between;
}

.title {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 0rem;
  font-size: .75rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  margin-bottom: 0px;
} 

.fill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  margin-top: 0rem;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 0rem;
  margin-bottom: -10px;
}

.fill {
  width: auto;
  min-width: 80px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  margin-top:10px;
  padding-top:0rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: #8C8BC1;
}
.style {
  width: auto;
  min-width: 100px;
  height: auto;
  /* margin: 20px; */
  padding-top: 0rem;
  padding-bottom: 0rem;
  text-align: center;
  background-color: #8C8BC1;
  flex: "0 0 25%";
  transition: all 0.3s ease-in-out;
}

.style-name {
  position: relative; /* set position to relative */
}

.style-name img {
  width: 20px;
  height: 20px;
  position: absolute; /* set position to absolute */
  bottom: 0; /* adjust the bottom property to position the image in the bottom */
  right: 0; /* adjust the right property to position the image in the right */
}
  



.selected {
  background-color: rgb(76, 73, 215);
}

h2 {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-family: "cuprum";
  margin: 0;
  color: rgb(255, 255, 255);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  user-select: none;
  cursor: default;
}

.add-new {
  width: auto;
  padding-left: 0px;
  /* min-width: 100px; */
  height: auto;
  /* margin: 20px; */
  /* padding-top: 0rem; */
  margin-top: 2rem;
  margin-left: .5rem;
  /* padding-bottom: 0rem; */
  text-align: left;
  display: flex;
  background-color: #8C8BC1;
  /* flex: "0 0 25%"; */
  transition: all 0.3s ease-in-out;
}

.add-custom {
  user-select: none;
  cursor: default;
  font-family: "cuprum";
  margin: 0;
  color: rgb(255, 255, 255);
  height: 35px;
  display: flex;
  align-items: left;
  display: flex;
  /* justify-content: center; */
  font-size: 16px;
}

.Regions {
  cursor: pointer;
  background-color: rgb(140, 139, 193);
  width: 200px;
  height: 50px;
  margin: auto;
  /* border: 1px solid #4b4b4b; */
  /* border-radius: 10px; */
  box-shadow: 0px 0px 5px #ccc;
  text-align: center;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}



/* .region-name {
   padding: 10px;
} */



/* .style {
    background-color: rgb(0, 107, 146);
    border-radius: 20px;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    margin: 1rem;
  }

  .pill-unselected {
    background-color: rgb(0, 107, 146);
    border-radius: 20px;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    margin: 1rem;
  }
  .pill-selected {
    background-color: rgb(223, 224, 224);
    border-radius: 20px;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    margin: 1rem;
  }

  .selected h2 {
    background-color: rgb(0, 107, 146);
    color: #f2f2f2;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    border-color: #4b4b4b;
  }
  
  .unselected h2 {
    background-color: rgb(0, 107, 146);
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
  } */
