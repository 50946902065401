* {
    box-sizing: border-box;
    font-family: 'Cuprum';
    font-weight: 400;
}

body {
    margin: 0;
    background-color: #040618;
    font-family: "cuprum";
    font-weight: 400;
}

.container {
    margin: 0rem;
    text-align: center;
    background-color: #040618;
}

.site-title-container {
    display: flex;
    align-items: center;
  }
  
  .site-title {
    margin-right: 1rem;
  }
  
  .site-title-container img {
    width: 40px;
    height: 40px;
  }

.nav {
    background-color: rgb(59, 58, 129);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
    height: 4rem;
    width: 100%;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    background-color: rgb(140, 139, 193);
    border-radius: 30px;
}

.site-title {
    font-size: 2rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
}

.nav li.active,
.nav li:hover {
    background-color: #09082F;
    border-radius: 30px;
}

.nav li:not(:last-child) {
    border-right: 2px solid black;
  }

.nav li:first-child.active {
    border-radius: 30px 0 0 30px;
}

.nav li:last-child.active {
    border-radius: 0 30px 30px 0;
}

.nav li:first-child:hover {
    border-radius: 30px 0 0 30px;
}

.nav li:last-child:hover {
    border-radius: 0 30px 30px 0;
}

@media (max-width: 768px) {
    .nav {
        width: auto; /* Set width to auto for smaller screens */
    }
}
