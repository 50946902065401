/* .new-beverage { */
    /* background-color: rgb(0, 107, 146); */
    /* padding: 1rem;
    margin: 2rem 1rem;
    width: max-content; */
    /* max-width: 95%; */
    /* border-radius: 12px; */
    /* text-align: left;
    background-color: yell; */
    /* box-shadow: 0 1px 8px #00000040; */
  /* } */
  
  .new-beverage button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    color: white;
    border-radius: 7.5px;
    margin-right: 1rem;
    background-color: #8C8BC1;
    display: flex;
    align-items: center;
    font-size: x-large;
    height: 15px;
  }
  
  .new-beverage button:hover,
  .new-beverage button:active {
    background-color: #4C49D7;
    border-color: #000000;
  }
  
  .new-beverage button.alternative {
    color: #000000;
    border-color: transparent;
    background-color: transparent;
  }
  
  .new-beverage button.alternative:hover,
  .new-beverage button.alternative:active {
    background-color: rgb(0, 107, 146);
  }