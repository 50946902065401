* {
  font-family: 'Cuprum';
  font-weight: 400;
}

.container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding-top: 1rem;
    margin-left: 1rem;
    padding-left: 1rem;
    margin-bottom: 5rem;

  }

  .new-and-search {
    display: flex;
    align-items: center;
    padding-left:1rem;
    margin-left: 1rem;
  }
  
  .search-bar {
    display:inline-flex;
    align-items: center;
    margin-left: 20px;
  }
  
  /* .search-bar input[type="text"]::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-image: url("/search.png");
    background-size: contain;
    background-repeat: no-repeat;
  } */

  .search-bar input[type="text"] {
    background-image: url("../../public/search.png");
    background-position: 10px center;
    background-repeat: no-repeat;
    padding-left: 40px; /* 10px for icon + 30px for spacing */
  }

  .search-bar label {
    margin-right: 10px;
  }
  
  .search-bar input {
    height: 30px;
    padding: 5px;
    border: none;
    border-radius: 7.5px;
    background-color: #f2f2f2;
  }
  
  .style-filter {
    height: 30px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #b1d21f;
  }
  .region-filter {
    height: 30px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #b1d21f;
  }
  
  .new-beverage {
    /* flex-basis: 50%; */
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0rem;
    padding-left: 0;
    margin-right: 6rem;
  }

  
  .new-beverage form {
    margin-top: 20px;
  }
  
  .new-beverage input,
  .new-beverage select {
    width: 100%;
    height: 30px;
    margin: 5px 0;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #f2f2f2;
  }